<template>
    <div>
        <b-modal
            id="modal-download-file"
            :title="i18nT(`Téléchargement`)"
            ok-only
            :ok-title="i18nT(`Fermer`)"
            ok-variant="secondary"
            size="lg"
            body-class='ren-modal-download-file'
            footer-class="ren-modal-download-file"
        >
            <div class="pt-2">
                <b-row v-for="(file, index) in fileFomrats" :key="'file' + index">
                    <b-card no-body class="mx-5 w-100 cursor-pointer ren-card">
                        <b-card-body class="text-center" @click="downloadFile(file.type)">
<!--                            <font-awesome-icon :icon="['fas', file.icon]" size="2x" />-->
                            <rt-icon :icon="file.icon" variant="light" :size="30" />
                            <p class="pt-1">{{ i18nT(`Télécharger en format`) }} {{ file.name }}</p>
                        </b-card-body>
                    </b-card>
                </b-row>
            </div>
        </b-modal>
    </div>
</template>

<script>

import {
    BModal,
    BRow,
    BCard,
    BCardBody,
} from 'bootstrap-vue'

export default {
    components: {
        BModal,
        BRow,
        BCard,
        BCardBody,
    },
    props: {
        downloadFileAction: {
            type: Array,
            default: null,
        }
    },
    data() {
        return {
            fileFomrats: [
                {name: 'Adobe PDF', icon: 'file-pdf', value: 1, type: 'pdf'},
                {name: 'Word', icon: 'file-word', value: 2, type: 'docx'},
                {name: 'Open Office', icon: 'file-lines', value: 3, type: 'odt'},
            ]
        }
    },
    methods: {
        downloadFile(type) {
            const findFile = this.downloadFileAction.find(item => item.type === type)
            if(findFile){
                return window.open(findFile.url, '_blank')
            }
        },
    },
}


</script>

<style>

.ren-card:hover {
    background-color: #F5F5F5;
}

/* .dark-layout .ren-card {
    background-color: #161d31 !important;
} */

.dark-layout .ren-card:hover {
    background-color: #242B3F !important;
}

.dark-layout .ren-modal-download-file {
    background-color: #161d31 !important;
}

.dark-layout .ren-modal-download-file .btn {
    background-color: #283046 !important;
    border: none;
}


</style>